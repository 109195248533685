<template>
<div>
  <div class="tops">
    <div style="margin-top:3%">店铺名称：<label>{{TsInfo.ShopName}}</label></div>
    <div><font color="#de3f00">店铺地址：</font><label>{{TsInfo.ShopDz}}</label></div>
  </div>

  <div style="width: 90%; margin-left: 5%; line-height: 40px;">
    <div class="layui-row">
      <div class="layui-col-xs4">
        <div class="grid-demo grid-demo-bg1" style="text-align:right;">
          <span>投诉标题：</span>
        </div>
      </div>
      <div class="layui-col-xs8">
        <div class="grid-demo">
          <input type="text"  v-model="TsInfo.Title"  autocomplete="off"  readonly="readonly" placeholder="" class="layui-input" runat="server" />
        </div>
      </div>
    </div>
    <div class="layui-row">
      <div class="layui-col-xs4">
        <div class="grid-demo grid-demo-bg1" style="text-align:right;">
          <span>投诉内容：</span>
        </div>
      </div>
      <div class="layui-col-xs8">
        <div class="grid-demo">
          <textarea class="layui-textarea"  v-model="TsInfo.Cont"  readonly="readonly" runat="server"></textarea>
        </div>
      </div>
    </div>
    <div class="layui-row">
      <div class="layui-col-xs4">
        <div class="grid-demo grid-demo-bg1" style="text-align:right;">
          <span>上传的照片：</span>
        </div>
      </div>
      <div class="layui-col-xs8">
              <van-image
                  width="200"
                  height="200"

                  :src='TsInfo.icont'

                  v>
              </van-image>
      </div>
    </div>
    <div class="layui-row">
      <div class="layui-col-xs4">
        <div class="grid-demo grid-demo-bg1" style="text-align:right;">
          <span>姓名：</span>
        </div>
      </div>
      <div class="layui-col-xs8">
        <div class="grid-demo">
          <input type="text"  readonly="readonly" autocomplete="off"  v-model="TsInfo.Name" placeholder="" class="layui-input" />
        </div>
      </div>
    </div>

    <div class="layui-row">
      <div class="layui-col-xs4">
        <div class="grid-demo grid-demo-bg1" style="text-align:right;">
          <span>联系方式：</span>
        </div>
      </div>
      <div class="layui-col-xs8">
        <div class="grid-demo">
          <input type="text" lay-verify="title"  v-model="TsInfo.LxFs"  readonly="readonly" autocomplete="off"   placeholder="" class="layui-input" />
        </div>
      </div>
    </div>
    <div class="layui-row">
      <div class="layui-col-xs4">
        <div class="grid-demo grid-demo-bg1" style="text-align:right;">
          <span>当前状态：</span>
        </div>
      </div>
      <div class="layui-col-xs8">
        <div class="grid-demo">
          <input type="text"   readonly="readonly"  v-model="TsInfo.Type" autocomplete="off"  placeholder="" class="layui-input" />
        </div>
      </div>
    </div>
    <div class="layui-row"  runat="server">
      <div class="layui-col-xs4">
        <div class="grid-demo grid-demo-bg1" style="text-align:right;">
          <span>回复内容：</span>
        </div>
      </div>
      <div class="layui-col-xs8">
        <div class="grid-demo">
          <input type="text"  readonly="readonly" v-model="TsInfo.HfNr" autocomplete="off" placeholder="" class="layui-input" />
        </div>
      </div>
    </div>
</div>
</div>
</template>

<script>
import {mgop} from "@aligov/jssdk-mgop";
import {Toast} from "vant";
import DES from "@/assets/js/DES";
import Emas from "@/assets/js/Emas";

export default {
name: "yhtsInfo",
  data(){
    return{
      ID:'',
      token:'',
      Info: '',
      TsInfo:{
        Title:'',
        Cont:'',
        icont:'',
        Name:'',
        LxFs:'',
        Type:'',
        HfNr:'',
        ShopName:'',
        ShopDz:''
      },

    }
  },
  created() {
      this.ID=this.$route.query.ID
      this.token=sessionStorage.getItem("token");
      this.fetchData();
    let openid = sessionStorage.getItem("Openid");
    let resname=sessionStorage.getItem("username");
    Emas.getLocationMsg(openid,resname);
  },
  methods:{
      async fetchData(){
        let strData='{"ID":"' + this.ID +'"}';
        console.log(strData)
        // alert(strData)
        this.Info=DES.encryptDes(strData)
        console.log(this.Info)
        // let a="{\"result\":\"ok\",\"msg\":{\"Title\":\"投诉标题\",\"Name\":\"吴松吉\",\"Cont\":\"投诉内容\",\"HfNr\":\"\",\"Type\":\"暂未处理\",\"LxFs\":\"13456106492\",\"icont\":\"http://113.31.115.78:8097/Upload/FileImg/2021111714325750_image.jpg\",\"ShopName\":\"宁波市威尔信息科技有限公司\",\"ShopDz\":\"凤山街道-凤仪路-92号\"}}";
        // let data=JSON.parse(a);
        // console.log(data.result)
        // if (data.result=="ok"){
        //   console.log(data.msg)
        //   this.TsInfo=data.msg
        // }
        // return;
        mgop({
          api: "mgop.will.mqsb.SjYhTs", // 必须
          host: "https://mapi.zjzwfw.gov.cn/",
          dataType: "JSON",
          type: "POST",
          data: {token:this.token,type:'4', Info: this.Info },
          appKey: "3n3b3538+2001833223+ekdhqo", // 必须
          onSuccess: (data) => {
            // alert(JSON.stringify(data))
            console.log("mgop run success login : " + JSON.stringify(data));
            if (data.data.result == "ok") {
              this.TsInfo=data.data.msg
            }else if(data.data.result == "401"){
              Toast.fail('token失效，请重新登录');
              this.$router.push({
                path: '/index'
              })
            } else {
              Toast.fail('获取数据失败，请重试');
              this.$router.push({
                path: '/yhtsList'
              })
            }
          },
          onFail: (err) => {
            // alert(JSON.stringify(err))
            console.log("mgop run error. error :  " + JSON.stringify(err));
            Toast.fail('获取数据失败，请重试');
            this.$router.push({
              path: '/yhtsList'
            })
          },
        });
      }
  }
}
</script>

<style scoped>
@import "../../../assets/css/layui.css";
* {
  margin: 0;
  padding: 0;
  font-family: 'Arial, Helvetica, sans-serif';
}

html,
body {
  height: 100%;
  background: #f5fbff;
}

.grid-demo {
  text-align: right;
}

.layui-input {
  width: 93%;
}

.layui-edge {
  right: 13% !important;
}

.layui-form-select dl {
  min-width: 93%;
}

span {
  line-height: 40px;
}

.layui-row {
  margin-top: 10px;
}

.showishide {
  display: none;
}

.tops {
  background:url(../../../assets/images/ts_02.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  height:120px;
  font-size:18px;
  line-height:30px;
  padding-top: 11px;
  color:#004e71;
  border-bottom: 2px #ccc dashed;
  padding-left: 30px;
}

.layui-btn-danger{

  color:#fff;
  border-radius:10px;
}
</style>